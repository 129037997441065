<template src="./template.html"></template>

<script>
import CreateAdType from "@/components/AdType/CreateDialog/index"
import EditAdType from "@/components/AdType/EditDialog/index"
import DeleteAdType from "@/components/AdType/DeleteDialog/index"
export default {
  name: "AdTypePage",
  components: {
    CreateAdType,
    EditAdType,
    DeleteAdType
  },
  data() {
    return {
      headers: [
        {
          text: '廣告類型',
          align: 'start',
          value: 'Name',
        },
        {
          text: '狀態',
          align: 'start',
          value: 'Status',
        },
        { text: '建立日期', value: 'NewTime' },
        { text: '動作', value: 'actions', sortable: false },
      ],
      footerprops: {
        'items-per-page-text': '每頁顯示',
      },
      ad_type: [],
    }
  },

  created() {
    this.LoadData()
  },

  methods: {
    OpenCreate() {
      this.$refs.CreateAdType.Open()
    },
    OpenEdit(item) {
      this.$refs.EditAdType.Open(item)
    },
    OpenDelete(item) {
      this.$refs.DeleteAdType.Open(item.ProjectTypeID)
    },
    async LoadData() {
      this.loading = true
      let response = await this.GetData("/admin/project/type")
      this.loading = false
      if (response != "error") {

        this.ad_type = []
        this.ad_type = response
      }
    },
  },

  filters: {
    status(val) {
      return (val == 'Y') ? "已啟用" : "已停用";
    },
  }
}
</script>